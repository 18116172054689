/* eslint-disable max-lines */
import { FC, Fragment } from "react"
import { Table } from "../../../../../../../../_webui/layout/components/Table"
import { Regex } from "../../../../../../../../constant/Regex"
import { Title } from "../../../../../../../../_webui/layout/components/Typography/Title"
import { Translate } from "../../../../../../../../constant/Translate"
import { ScheduleInfoDetailsType } from "../../../../../models/add"
import { SchedulesAddTable } from "../../../Table"
import { SchedulesAddDetailProps } from "./models"
import { Styled } from "./styled"
import { ItemSelect } from "../../../../../../../../_webui/layout/components/Form/Item/Select"
import { ItemInput } from "../../../../../../../../_webui/layout/components/Form/Item/Input"
import { validatorRequiredField } from "../../../../../../../../utils/validator/requiredField"

const { REGEX_IS_DESKTOP } = Regex
const { StyledTableRemark } = Styled
const {
  HELP_H0034,
  MESSAGE_E0151,
  TEXT_T215,
  TEXT_T234,
  TEXT_T235,
  TEXT_T282,
  TEXT_T283,
  TEXT_T382,
} = Translate

export const SchedulesAddDetail: FC<SchedulesAddDetailProps> = (props) => {
  const {
    departments,
    detailColumnsFirst,
    detailColumnsSecond,
    detailDataSource,
    detailIndexTabActive,
    detailRowSelection,
    detailSelectedRowKeys,
    detailTabsItems,
    detailValidMsg,
    handleAddDetailItem,
    handleDeleteDetailItem,
    isActiveBumon,
    isTableLoading,
    isTwoWay,
    isVisibleBtn,
    onChangeTravelPurpose,
    onClickDetailTab,
    purposeTravelList,
  } = props

  return (
    <Fragment>
      <ItemSelect
        label={TEXT_T215}
        name="travelPurposeId"
        onChange={onChangeTravelPurpose}
        required
        rules={[validatorRequiredField]}
        selectItem={purposeTravelList}
      />

      {isActiveBumon && (
        <ItemSelect
          isBgBlue
          label={TEXT_T234}
          name="bumonCd"
          required
          rules={[validatorRequiredField]}
          selectItem={departments}
          tooltip={HELP_H0034}
        />
      )}

      <ItemInput label={TEXT_T235} maxLength={100} name="mainPersonName" />

      <Title type="danger" titleLeft={TEXT_T382} />
      {detailValidMsg && <Title type="danger" titleLeft={detailValidMsg} />}
      {isTwoWay && <Title type="danger" titleLeft={MESSAGE_E0151} />}
      <SchedulesAddTable
        activeKey={detailIndexTabActive}
        addText={TEXT_T282}
        columns={detailColumnsFirst}
        dataSource={detailDataSource}
        dataTableSelected={detailSelectedRowKeys}
        deleteText={TEXT_T283}
        handleAdd={handleAddDetailItem}
        handleDelete={handleDeleteDetailItem}
        id="table-schedules-add-detail"
        isLoading={isTableLoading}
        isVisibleBtn={isVisibleBtn}
        items={detailTabsItems}
        onTabClick={onClickDetailTab}
        rowKey={(record: ScheduleInfoDetailsType) => record.travelDetailId}
        rowSelection={detailRowSelection}
      />

      {REGEX_IS_DESKTOP && (
        <StyledTableRemark>
          <Table
            columns={detailColumnsSecond}
            dataSource={detailDataSource}
            isBackgroundFirstChild
            isLoading={isTableLoading}
            pagination={false}
          />
        </StyledTableRemark>
      )}
    </Fragment>
  )
}
