import { getFullName } from "../../../../../../../utils/getFullName"
import {
  TravelerInfoResponseType,
  TravelerInfoType,
} from "../../../../components/add/Form/ContentCollapse/Personal/models"

export const getTravelerInfo = (
  travelerInfoResponse: TravelerInfoResponseType,
): TravelerInfoType => ({
  dispatchTypeDetailName: travelerInfoResponse.dispatchTypeDetailName,
  fullNameKanji: getFullName(
    travelerInfoResponse.kanjiSurname,
    travelerInfoResponse.kanjiName,
    true,
  ),
  fullNameRomaji: getFullName(
    travelerInfoResponse.romajiSurname,
    travelerInfoResponse.romajiName,
  ),
  kanjiPassportFullName: getFullName(
    travelerInfoResponse.kanjiPassportSurname,
    travelerInfoResponse.kanjiPassportName,
    true,
  ),
  personEmailAddress: travelerInfoResponse.personEmailAddress,
  romajiPassportFullName: getFullName(
    travelerInfoResponse.romajiPassportSurname,
    travelerInfoResponse.romajiPassportName,
  ),
})
