/* eslint-disable max-lines */
import { Translate } from "../../../../../../constant/Translate"
import { GeneralMastersRemarkType } from "../../../../../../utils/apis/getGeneralMasters/models"
import {
  ScheduleDataType,
  ScheduleInfoDetailsType,
  ScheduleInfoLodgingsType,
  ScheduleInfoStaysType,
  ScheduleInfoType,
} from "../../../models/add"

const INITIAL_VALUES_SCHEDULES_INFO: ScheduleInfoType = {
  bumonCd: "",
  codeConductConfirmation: false,
  differentInforCheck: false,
  dispatchEndDate: "",
  dispatchStartDate: "",
  dispatchTypeDetailId: "",
  insuranceCompanyName: "",
  insuranceCompanyPhoneNumber: "",
  insuranceSonsignor: "",
  insuranceTicketNumber: "",
  locationPersonName: "",
  mainPersonName: "",
  managerRemarks1: "",
  managerRemarks2: "",
  managerRemarks3: "",
  managerRemarks4: "",
  managerRemarks5: "",
  procurementControlNumber: "",
  projectNameEn: "",
  projectNameJp: "",
  travelPurposeId: "",
  travelerId: "",
  workPlaceCountryCode: "",
}

const INITIAL_VALUES_SCHEDULE_DATA: ScheduleDataType = {
  details: [],
  info: INITIAL_VALUES_SCHEDULES_INFO,
  lodgings: [],
  stays: [],
}

const { TEXT_T153, TEXT_T247, TEXT_T248, TEXT_T249, TEXT_T250 } = Translate

const INITIAL_VALUES_REMARK_NAMES: GeneralMastersRemarkType = {
  remarksName1: TEXT_T153,
  remarksName2: TEXT_T247,
  remarksName3: TEXT_T248,
  remarksName4: TEXT_T249,
  remarksName5: TEXT_T250,
}

const ITINERARY_DATA_SOURCE_NEW: ScheduleInfoDetailsType = {
  arrivalCities: [],
  arrivalCityCode: "",
  arrivalCityName: "",
  arrivalCityNameOther: "",
  arrivalCountryCode: "",
  arrivalDateTime: "",
  departureCities: [],
  departureCityCode: "",
  departureCityName: "",
  departureCityNameOther: "",
  departureCountryCode: "",
  departureDateTime: "",
  departureFlightNumber: "",
  isTransit: false,
  othersRemarks1: "",
  othersRemarks2: "",
  othersRemarks3: "",
  othersRemarks4: "",
  othersRemarks5: "",
  remarkNames: {
    remarksName1: "",
    remarksName2: "",
    remarksName3: "",
    remarksName4: "",
    remarksName5: "",
  },
  transportation: "",
  travelDetailId: "",
}

const ACCOMMODATION_DATA_SOURCE_NEW: ScheduleInfoLodgingsType = {
  lodgingAddress: "",
  lodgingCheckin: "",
  lodgingCheckout: "",
  lodgingName: "",
  lodgingPhoneNumber: "",
  travelLodgingId: "",
}

const PLACE_OF_STAY_CONTACT_DATA_SOURCE_NEW: ScheduleInfoStaysType = {
  emailAddress1: "",
  emailAddress2: "",
  locationPhoneNumber1: "",
  locationPhoneNumber2: "",
  satellitePhoneNumber: "",
  travelStayId: "",
}

const COLLAPSE_ACTIVE = ["1", "2", "3", "4", "5", "6", "7", "8"]

export const InitialValues = {
  ACCOMMODATION_DATA_SOURCE_NEW,
  COLLAPSE_ACTIVE,
  INITIAL_VALUES_REMARK_NAMES,
  INITIAL_VALUES_SCHEDULE_DATA,
  ITINERARY_DATA_SOURCE_NEW,
  PLACE_OF_STAY_CONTACT_DATA_SOURCE_NEW,
}
