const E0017 = "E0017"
const E0104 = "E0104"
const E0105 = "E0105"
const E0106 = "E0106"
const E0107 = "E0107"
const SCHEDULES_DETAIL_ROW_PREFIX = "schedulesAddDetail"
const SCHEDULES_VALIDATE_TABLE_DETAIL = "Detail"
const SCHEDULES_VALIDATE_TABLE_LODGING = "Lodging"

const DPTD_REQUIRED_URGENT_CONTACT = [
  "DPTD002",
  "DPTD003",
  "DPTD004",
  "DPTD005",
  "DPTD008",
  "DPTD009",
  "DPTD010",
  "DPTD011",
]

export const Variables = {
  DPTD_REQUIRED_URGENT_CONTACT,
  E0017,
  E0104,
  E0105,
  E0106,
  E0107,
  SCHEDULES_DETAIL_ROW_PREFIX,
  SCHEDULES_VALIDATE_TABLE_DETAIL,
  SCHEDULES_VALIDATE_TABLE_LODGING,
}
