/* eslint-disable complexity */
/* eslint-disable max-lines */
import { FC, Fragment } from "react"
import { ItemDatePicker } from "../../../../../../../../_webui/layout/components/Form/Item/DatePicker"
import { ItemInput } from "../../../../../../../../_webui/layout/components/Form/Item/Input"
import { Translate } from "../../../../../../../../constant/Translate"
import { validatorHalfSize } from "../../../../../../../../utils/validator/halfSize"
import { validatorRangeDate2 } from "../../../../../../../../utils/validator/rangeDate2"
import { validatorRequiredField } from "../../../../../../../../utils/validator/requiredField"
import { SchedulesAddAssignmentProps } from "./models"
import { Styled } from "./styled"

const { StyledErrorTitle } = Styled
const {
  HELP_H0019,
  TEXT_T199,
  TEXT_T200,
  TEXT_T201,
  TEXT_T236,
  TEXT_T237,
  TEXT_T238,
} = Translate

export const SchedulesAddAssignment: FC<SchedulesAddAssignmentProps> = (
  props,
) => {
  const {
    assignmentValidMsg,
    isRoleAdmin,
    isSubmitValidate,
    onBlur,
    onChangeDate,
    onKeyDown,
  } = props

  const _validatorRangeDate2 = isSubmitValidate
    ? [
        validatorRangeDate2({
          namePathEndDate: "dispatchEndDate",
          namePathStartDate: "dispatchStartDate",
        }),
      ]
    : []

  return (
    <Fragment>
      <ItemInput label={TEXT_T199} maxLength={200} name="projectNameJp" />

      <ItemInput
        label={TEXT_T200}
        maxLength={200}
        name="projectNameEn"
        rules={[validatorHalfSize]}
      />

      {isRoleAdmin ? (
        <ItemInput
          label={TEXT_T201}
          maxLength={50}
          name="procurementControlNumber"
          rules={[validatorHalfSize]}
          tooltip={HELP_H0019}
        />
      ) : (
        <ItemInput
          isReadOnly
          label={TEXT_T201}
          name="procurementControlNumber"
        />
      )}

      <ItemInput label={TEXT_T236} maxLength={100} name="locationPersonName" />

      {assignmentValidMsg && (
        <StyledErrorTitle type="danger" titleLeft={assignmentValidMsg} />
      )}

      <ItemDatePicker
        label={TEXT_T237}
        name="dispatchStartDate"
        onBlur={(event) => onBlur(event, TEXT_T237, "dispatchStartDate")}
        onKeyDown={(event) => onKeyDown(event, TEXT_T237, "dispatchStartDate")}
        required
        rules={[validatorRequiredField, ..._validatorRangeDate2]}
      />

      <ItemDatePicker
        label={TEXT_T238}
        name="dispatchEndDate"
        onBlur={(event) => onBlur(event, TEXT_T238, "dispatchEndDate")}
        onChange={() => onChangeDate("dispatchStartDate")}
        onKeyDown={(event) => onKeyDown(event, TEXT_T238, "dispatchEndDate")}
        required
        rules={[validatorRequiredField]}
      />
    </Fragment>
  )
}
