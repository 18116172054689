import { useEffect, useState } from "react"
import { useHistory, useLocation } from "react-router-dom"
import { GridItemType } from "../../../../../../../_webui/layout/components/Grid/models"
import { Routers } from "../../../../../../../constant/Routers"
import { AxiosErrorType } from "../../../../../../../infrastructure/axiosError/models"
import { pushStateError } from "../../../../../../../utils/pushStateError"
import { travelerApi } from "../../../../../travelers/apis"
import { InitialValues } from "../../../../constants/addSuccess/initialValues"
import {
  TravelerInfoResponseType,
  UseGridConfigsProps,
} from "../../../../models/addSuccess"
import { convertDataTravelerInfo } from "./convertDataSource"
import { getAssignmentGridConfig } from "./getAssignmentGridConfig"
import { getConfirmGridConfig } from "./getConfirmGridConfig"
import { getInsuranceGridConfig } from "./getInsuranceGridConfig"
import { getOtherGridConfig } from "./getOtherGridConfig"
import { getPersonalGridConfig } from "./getPersonalGridConfig"
import { getDetailsGridConfig } from "./getDetailsGridConfig"

const { INITIAL_VALUES_TRAVELER_INFO } = InitialValues
const { SCR973 } = Routers

export const useGridConfigs = (props: UseGridConfigsProps) => {
  const { isVisibleOtherRemarks, scheduleInfo, travelerId } = props
  const { push } = useHistory()
  const { pathname } = useLocation()
  const [personalGridItem, setPersonalGridItem] = useState<GridItemType[]>(
    getPersonalGridConfig(INITIAL_VALUES_TRAVELER_INFO),
  )

  useEffect(() => {
    if (travelerId)
      // APIを呼び出し、APIからの応答結果を「渡航者情報応答」変数に設定する。
      travelerApi
        .getTravelerBase(travelerId)
        .then((travelerInfoResponse: TravelerInfoResponseType) => {
          const data = convertDataTravelerInfo(travelerInfoResponse)
          setPersonalGridItem(getPersonalGridConfig(data))
        })
        .catch((error: AxiosErrorType) => {
          const stateError = pushStateError(error)
          push({ pathname: SCR973, state: stateError })
        })
  }, [travelerId])

  const assignmentGridItem = getAssignmentGridConfig(scheduleInfo)
  const detailsGridItem = getDetailsGridConfig(scheduleInfo)
  const insuranceGridItem = getInsuranceGridConfig(scheduleInfo)
  const otherGridItem = getOtherGridConfig(
    pathname.includes("/schedules/detail"),
    isVisibleOtherRemarks,
    scheduleInfo,
  )
  const confirmGridItem = getConfirmGridConfig(scheduleInfo)

  return {
    assignmentGridItem,
    confirmGridItem,
    detailsGridItem,
    insuranceGridItem,
    otherGridItem,
    personalGridItem,
  }
}
