/* eslint-disable complexity */
import { GetScheduleDataAddProps } from "../../../../models/add"
import {
  ScheduleDataAddType,
  ScheduleInfoAddType,
} from "../../../../models/add/DataAdd"
import { getScheduleDataTableAdd } from "../getDataTableAdd"
import { getScheduleInfoAdd } from "./getInfoAdd"

export const getScheduleDataAdd = (
  props: GetScheduleDataAddProps,
): ScheduleDataAddType => {
  const {
    allCountries,
    dataSubmit,
    departments,
    detailDataSource,
    detailIndexIdentity,
    id,
    isFromSCR114UserSearch,
    isSchedulesAdd,
    isSchedulesEdit,
    lodgingIndexIdentity,
    purposeTravelList,
    schedulesData,
    stayIndexIdentity,
    transportationList,
    travelerId,
  } = props

  const scheduleInfoSubmit: ScheduleInfoAddType = isSchedulesEdit
    ? {
        travelScheduleId: id,
        ...getScheduleInfoAdd({
          dataSubmit,
          departments,
          purposeTravelList,
          travelerId,
        }),
      }
    : getScheduleInfoAdd({
        dataSubmit,
        departments,
        purposeTravelList,
        travelerId,
      })

  const {
    schedulesAddDetailsSubmit,
    schedulesAddLodgingsSubmit,
    schedulesAddStaysSubmit,
  } = getScheduleDataTableAdd({
    allCountries,
    dataSubmit,
    detailDataSource,
    detailIndexIdentity,
    id,
    isSchedulesAdd,
    isSchedulesEdit,
    lodgingIndexIdentity,
    schedulesData,
    stayIndexIdentity,
    transportationList,
  })

  return {
    details: schedulesAddDetailsSubmit,
    info: scheduleInfoSubmit,
    isFromSCR114UserSearch: isFromSCR114UserSearch,
    lodgings: schedulesAddLodgingsSubmit,
    stays: schedulesAddStaysSubmit,
  }
}
