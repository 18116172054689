const SCHEDULES_ADD_REQUIRED_FIELDS = [
  "travelPurposeId",
  "dispatchStartDate",
  "dispatchEndDate",
  "codeConductConfirmation",
]

const INSURANCE_REQUIRED_FIELDS = [
  "insuranceCompanyPhoneNumber",
  "insuranceTicketNumber",
  "insuranceSonsignor",
]

export const RequiredFields = {
  INSURANCE_REQUIRED_FIELDS,
  SCHEDULES_ADD_REQUIRED_FIELDS,
}
