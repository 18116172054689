import { FC, Fragment } from "react"
import { ItemInput } from "../../../../../../../../_webui/layout/components/Form/Item/Input"
import { Translate } from "../../../../../../../../constant/Translate"
import { KanjiPassportFullName } from "../../../../../../../../_webui/layout/components/Typography/KanjiPassportFullName"
import { RomajiPassportFullName } from "../../../../../../../../_webui/layout/components/Typography/RomajiPassportFullName"
import { Title } from "../../../../../../../../_webui/layout/components/Typography/Title"
import { SchedulesAddPersonalProps } from "./models"

const { TEXT_T003, TEXT_T006, TEXT_T025, MESSAGE_W0017 } = Translate

export const SchedulesAddPersonal: FC<SchedulesAddPersonalProps> = (props) => {
  const { isInsuranceAndUrgent } = props
  return (
    <Fragment>
      <ItemInput isReadOnly label={TEXT_T003} name="fullNameKanji" />
      <ItemInput isReadOnly label={TEXT_T006} name="fullNameRomaji" />
      <ItemInput
        isReadOnly
        label={<KanjiPassportFullName />}
        name="kanjiPassportFullName"
      />
      <ItemInput
        isReadOnly
        label={<RomajiPassportFullName />}
        name="romajiPassportFullName"
      />
      <ItemInput isReadOnly label={TEXT_T025} name="dispatchTypeDetailName" />
      {isInsuranceAndUrgent && (
        <Title type="danger" titleLeft={MESSAGE_W0017} />
      )}

      <ItemInput isReadOnly isHidden name="personEmailAddress" />
    </Fragment>
  )
}
