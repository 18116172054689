/* eslint-disable max-lines */
import { FC } from "react"
import { Collapse } from "../../../../../../../_webui/layout/components/Collapse"
import { Translate } from "../../../../../../../constant/Translate"
import { SchedulesAddAssignment } from "./Assignment"
import { SchedulesAddConfirm } from "./Confirm"
import { SchedulesAddDetail } from "./Detail"
import { SchedulesAddInsurance } from "./Insurance"
import { SchedulesAddLodging } from "./Lodging"
import { SchedulesAddOther } from "./Other"
import { SchedulesAddPersonal } from "./Personal"
import { SchedulesAddStay } from "./Stay"
import { SchedulesAddCollapseProps } from "./models"

const {
  TEXT_T520,
  TEXT_T526,
  TEXT_T528,
  TEXT_T546,
  TEXT_T549,
  TEXT_T550,
  TEXT_T551,
  TEXT_T669,
} = Translate

export const SchedulesAddCollapse: FC<SchedulesAddCollapseProps> = (props) => {
  const {
    assignmentValidMsg,
    defaultActiveKey,
    departments,
    detailColumnsFirst,
    detailColumnsSecond,
    detailDataSource,
    detailIndexTabActive,
    detailRowSelection,
    detailSelectedRowKeys,
    detailTabsItems,
    detailValidMsg,
    handleAddDetailItem,
    handleAddLodgingItem,
    handleAddStayItem,
    handleDeleteDetailItem,
    handleDeleteLodgingItem,
    handleDeleteStayItem,
    isActiveBumon,
    isDifferentInfoChecked,
    isInsuranceRequired,
    isRoleAdmin,
    isSubmitValidate,
    isTableLoading,
    isTwoWay,
    isVisibleBtn,
    isVisibleOtherRemarks,
    lodgingColumns,
    lodgingDataSource,
    lodgingIndexTabActive,
    lodgingRowSelection,
    lodgingSelectedRowKeys,
    lodgingTabsItems,
    lodgingValidMsg,
    onBlur,
    onChangeCollapse,
    onChangeDate,
    onChangeDifferentInfo,
    onChangeTravelPurpose,
    onClickDetailTab,
    onClickLodgingTab,
    onClickStayTab,
    onKeyDown,
    purposeTravelList,
    stayColumns,
    stayDataSource,
    stayIndexTabActive,
    stayRowSelection,
    staySelectedRowKeys,
    stayTabsItems,
    isInsuranceAndUrgent,
  } = props

  const panelItems = [
    {
      content: (
        <SchedulesAddPersonal isInsuranceAndUrgent={isInsuranceAndUrgent} />
      ),
      header: TEXT_T520,
      key: "1",
    },
    {
      content: <SchedulesAddConfirm />,
      header: TEXT_T669,
      key: "2",
    },
    {
      content: (
        <SchedulesAddAssignment
          assignmentValidMsg={assignmentValidMsg}
          isRoleAdmin={isRoleAdmin}
          isSubmitValidate={isSubmitValidate}
          onBlur={onBlur}
          onChangeDate={onChangeDate}
          onKeyDown={onKeyDown}
        />
      ),
      header: TEXT_T551,
      key: "3",
    },
    {
      content: (
        <SchedulesAddInsurance
          isDifferentInfoChecked={isDifferentInfoChecked}
          isInsuranceRequired={isInsuranceRequired}
          onChangeDifferentInfo={onChangeDifferentInfo}
        />
      ),
      header: TEXT_T526,
      key: "4",
    },
    {
      content: (
        <SchedulesAddDetail
          departments={departments}
          detailColumnsFirst={detailColumnsFirst}
          detailColumnsSecond={detailColumnsSecond}
          detailDataSource={detailDataSource}
          detailIndexTabActive={detailIndexTabActive}
          detailRowSelection={detailRowSelection}
          detailSelectedRowKeys={detailSelectedRowKeys}
          detailTabsItems={detailTabsItems}
          detailValidMsg={detailValidMsg}
          handleAddDetailItem={handleAddDetailItem}
          handleDeleteDetailItem={handleDeleteDetailItem}
          isActiveBumon={isActiveBumon}
          isTableLoading={isTableLoading}
          isTwoWay={isTwoWay}
          isVisibleBtn={isVisibleBtn}
          onChangeTravelPurpose={onChangeTravelPurpose}
          onClickDetailTab={onClickDetailTab}
          purposeTravelList={purposeTravelList}
        />
      ),
      header: TEXT_T546,
      key: "5",
    },
    {
      content: (
        <SchedulesAddLodging
          handleAddLodgingItem={handleAddLodgingItem}
          handleDeleteLodgingItem={handleDeleteLodgingItem}
          isTableLoading={isTableLoading}
          isVisibleBtn={isVisibleBtn}
          lodgingColumns={lodgingColumns}
          lodgingDataSource={lodgingDataSource}
          lodgingIndexTabActive={lodgingIndexTabActive}
          lodgingRowSelection={lodgingRowSelection}
          lodgingSelectedRowKeys={lodgingSelectedRowKeys}
          lodgingTabsItems={lodgingTabsItems}
          lodgingValidMsg={lodgingValidMsg}
          onClickLodgingTab={onClickLodgingTab}
        />
      ),
      header: TEXT_T549,
      key: "6",
    },
    {
      content: (
        <SchedulesAddStay
          handleAddStayItem={handleAddStayItem}
          handleDeleteStayItem={handleDeleteStayItem}
          isTableLoading={isTableLoading}
          isVisibleBtn={isVisibleBtn}
          onClickStayTab={onClickStayTab}
          stayColumns={stayColumns}
          stayDataSource={stayDataSource}
          stayIndexTabActive={stayIndexTabActive}
          stayRowSelection={stayRowSelection}
          staySelectedRowKeys={staySelectedRowKeys}
          stayTabsItems={stayTabsItems}
        />
      ),
      header: TEXT_T550,
      key: "7",
    },
  ]

  if (isVisibleOtherRemarks)
    panelItems.push({
      content: <SchedulesAddOther />,
      header: TEXT_T528,
      key: "8",
    })

  return (
    <Collapse
      defaultActiveKey={defaultActiveKey}
      onChangeCollapse={onChangeCollapse}
      panelItems={panelItems}
    />
  )
}
