import moment from "moment"
import { GeneralCode } from "../../../../../../../../../constant/GeneralCode"
import {
  formatDateExport,
  getDayExport,
} from "../../../../../../../../../utils/getDate"
import { getHourExport } from "../../../../../../../../../utils/getTime"
import { SchedulesListDataDetailsType } from "../../../../../../models/list/data"
import { SchedulesExportFRM101Type } from "../../../../../../models/list/export"
import { Translate } from "../../../../../../../../../constant/Translate"
import { getValueByLanguage } from "../../../../../../../../../utils/getValueByLanguage"

const { OTHER } = GeneralCode
const { TEXT_T220 } = Translate

export const convertDataExport = (
  data: SchedulesListDataDetailsType[],
): SchedulesExportFRM101Type[] =>
  data
    .sort((a, b) =>
      moment(a.departureDateTime).diff(moment(b.departureDateTime)),
    )
    .map((item) => {
      const arrivalDateTime = moment(item.arrivalDateTime)
        .utc()
        .format("YYYY-MM-DD")
      const departureDateTime = moment(item.departureDateTime)
        .utc()
        .format("YYYY-MM-DD")
      const diffDepartureAndArrival = moment(arrivalDateTime).diff(
        moment(departureDateTime),
        "day",
      )
      const getValueOther = (code: string, value1: string, value2: string) =>
        code === OTHER ? value1 : value2
      const getLodgingName = (value: string) =>
        value ? getValueByLanguage(`（${value}）`, ` (${value})`) : ""
      const projectName = getValueByLanguage(
        item.projectNameJp,
        item.projectNameEn,
      ).toString()
      return {
        arrivalCityValueOrArrivalCityName: getValueOther(
          item.arrivalCityCode,
          item.arrivalCityNameOther,
          item.arrivalCityName,
        ),
        arrivalCountryName: item.arrivalCountryName,
        departureCountryName: item.departureCountryName,
        departureCountryNameOrDepartureCityName: getValueOther(
          item.departureCityCode,
          item.departureCityNameOther,
          item.departureCityName,
        ),
        departureDate: formatDateExport(item.departureDateTime),
        departureDateTimeAndArrivalDateTime: `${getHourExport(
          item.departureDateTime,
        )} - ${getHourExport(item.arrivalDateTime)} ${
          diffDepartureAndArrival ? `(+${diffDepartureAndArrival})` : ""
        }`,
        departureDay: getDayExport(item.departureDateTime),
        departureFlightNumberAndTransportationName: `${item.departureFlightNumber}/${item.transportationName}`,
        dispatchStartDate: formatDateExport(item.dispatchStartDate),
        lodgingName: item.isTransit
          ? `${TEXT_T220}${getLodgingName(item.lodgingName)}`
          : item.lodgingName,
        projectName: projectName,
        travelPurposeName: item.travelPurposeName,
      }
    })
