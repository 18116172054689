/* eslint-disable max-statements */
import Excel from "exceljs"
import { saveAs } from "file-saver"
import { SchedulesListDataType } from "../../../../../models/list"
import { exportFileName } from "../../../../../../../../utils/exportFileName"
import { convertDataExport } from "./convertDataExport"
import { cellConfig } from "./cellConfig"
import { columnsConfig } from "./columnsConfig"
import { getValueByLanguage } from "../../../../../../../../utils/getValueByLanguage"
import { getFullName } from "../../../../../../../../utils/getFullName"
import { Translate } from "../../../../../../../../constant/Translate"
import { SchedulesListDataDetailsType } from "../../../../../models/list/data"

const { EXPORT_EP0193 } = Translate

export const exportFRM101 = async (data: SchedulesListDataType[]) => {
  const workbook = new Excel.Workbook()

  const _data: SchedulesListDataType[] = []
  data.forEach((item) => {
    const dataCurrent = _data.find(
      (itemFilter) => itemFilter.csv.userId === item.csv.userId,
    )
    const dataNew: SchedulesListDataDetailsType[] = item.details.map(
      (itemDetail) => ({
        ...itemDetail,
        projectNameEn: item.info.projectNameEn,
        projectNameJp: item.info.projectNameJp,
        travelPurposeName: item.info.travelPurposeName,
      }),
    )
    if (dataCurrent) {
      const currentIndex = _data.findIndex(
        (itemFilter) => itemFilter.csv.userId === item.csv.userId,
      )
      _data[currentIndex].details = [...dataCurrent.details, ...dataNew]
    } else {
      _data.push({
        ...item,
        details: dataNew,
      })
    }
  })

  _data.map((itemData) => {
    const sheetName = itemData.csv.userId.split("@")
    const worksheet = workbook.addWorksheet(sheetName[0])
    const convertData = convertDataExport(itemData.details)

    const fullNameRomaji = getFullName(
      itemData.info.romajiSurname,
      itemData.info.romajiName,
    )
    const _fullNameKanji = getFullName(
      itemData.info.kanjiSurname,
      itemData.info.kanjiName,
    )
    const fullNameKanji = _fullNameKanji
      ? `${_fullNameKanji}（${fullNameRomaji}）`
      : fullNameRomaji
    worksheet.mergeCells("A1:J1")
    worksheet.getCell("A1").value = getValueByLanguage(
      fullNameKanji,
      fullNameRomaji,
    )
    columnsConfig(worksheet)

    for (let index = 0; index < convertData.length; index++) {
      const item = convertData[index]
      worksheet.getCell(`$A${index + 3}`).value = item.departureDate
      worksheet.getCell(`$B${index + 3}`).value = item.departureDay
      worksheet.getCell(`$C${index + 3}`).value =
        item.departureDateTimeAndArrivalDateTime
      worksheet.getCell(`$D${index + 3}`).value = item.travelPurposeName
      worksheet.getCell(`$E${index + 3}`).value =
        item.departureFlightNumberAndTransportationName
      worksheet.getCell(`$F${index + 3}`).value = item.departureCountryName
      worksheet.getCell(`$G${index + 3}`).value =
        item.departureCountryNameOrDepartureCityName
      worksheet.getCell(`$H${index + 3}`).value = item.arrivalCountryName
      worksheet.getCell(`$I${index + 3}`).value =
        item.arrivalCityValueOrArrivalCityName
      worksheet.getCell(`$J${index + 3}`).value = item.lodgingName
    }
    cellConfig(worksheet)
  })

  const buffer = await workbook.xlsx.writeBuffer()
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
  const fileExtension = ".xlsx"

  const blob = new Blob([buffer], { type: fileType })

  saveAs(blob, exportFileName(EXPORT_EP0193) + fileExtension)
}
