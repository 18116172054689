import { Translate } from "../../../../../../../../constant/Translate"
import { GridItemType } from "../../../../../../../../_webui/layout/components/Grid/models"
import { KanjiPassportFullName } from "../../../../../../../../_webui/layout/components/Typography/KanjiPassportFullName"
import { RomajiPassportFullName } from "../../../../../../../../_webui/layout/components/Typography/RomajiPassportFullName"
import { TravelerInfoType } from "../../../../../models/addSuccess"

const { TEXT_T003, TEXT_T006, TEXT_T025 } = Translate

export const getPersonalGridConfig = (
  travelerInfo: TravelerInfoType,
): GridItemType[] => [
  {
    key: TEXT_T003,
    text: travelerInfo.fullNameKanji,
  },
  {
    key: TEXT_T006,
    text: travelerInfo.fullNameRomaji,
  },
  {
    key: <KanjiPassportFullName />,
    text: travelerInfo.kanjiPassportFullName,
  },
  {
    key: <RomajiPassportFullName />,
    text: travelerInfo.romajiPassportFullName,
  },
  {
    key: TEXT_T025,
    text: travelerInfo.dispatchTypeDetailName,
  },
]
