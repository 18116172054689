/* eslint-disable max-statements */
/* eslint-disable max-lines */
/* eslint-disable complexity */
import { RcFile } from "antd/lib/upload"
import { useEffect, useRef } from "react"
import { File } from "../../../../../../../../constant/File"
import { GeneralCode } from "../../../../../../../../constant/GeneralCode"
import { Routers } from "../../../../../../../../constant/Routers"
import { Translate } from "../../../../../../../../constant/Translate"
import { AxiosErrorType } from "../../../../../../../../infrastructure/axiosError/models"
import { backToLogin } from "../../../../../../../../utils/backToLogin"
import { openNotification } from "../../../../../../../../utils/openNotification"
import { pushStateError } from "../../../../../../../../utils/pushStateError"
import { replaceStringParam } from "../../../../../../../../utils/replaceStringParam"
import { commonApi } from "../../../../../apis"
import {
  FilesUploadResType,
  UploadFileApiType,
  UploadFileType,
  UseFilesUploadType,
} from "../../../../../models/files/upload"
import { useVariables } from "../useVariables"
import { PreLine } from "../../../../../../../../_webui/layout/components/Typography/PreLine"
import { getValueByLanguage } from "../../../../../../../../utils/getValueByLanguage"

const {
  FAQ_TYPE,
  MEASURES_TYPE,
  SAFETY_MEASURES_TYPE,
  SUBADMIN_MANUAL_TYPE,
  TEMPLATE_TYPE,
  USER_MANUAL_TYPE,
} = GeneralCode
const ADMIN_TYPE = [
  FAQ_TYPE,
  SAFETY_MEASURES_TYPE,
  SUBADMIN_MANUAL_TYPE,
  USER_MANUAL_TYPE,
]
const { SCR970, SCR973 } = Routers

const {
  MESSAGE_E0022,
  MESSAGE_E0023,
  MESSAGE_E0024,
  MESSAGE_E0054,
  MESSAGE_E0068,
  MESSAGE_E0095,
  MESSAGE_I0015,
} = Translate
const {
  FILE_MAX_UPLOAD,
  FILE_NAME_LIMIT,
  FILE_SIZE_LIMIT_KB,
  FILE_SIZE_LIMIT_MB,
  FILE_TYPE_ALLOW_UPLOAD_ACCEPT,
  FILE_TYPE_ALLOW_UPLOAD_EXTENSION,
} = File

export const useFilesUpload = (): UseFilesUploadType<UploadFileType> => {
  const {
    allCountries,
    columns,
    countryCode,
    countryName,
    currentUsage,
    fileCategory,
    fileInfoList,
    fileNameList,
    fileType,
    fileTypeList,
    fileTypeName,
    form,
    isCommonUploadRegister,
    isCountry,
    isDisabledCountries,
    isHiddenBtn,
    isLoading,
    isLoadingLayout,
    isOpen,
    isOpenConfirm,
    isOpenConfirmDuplicate,
    isResetTable,
    isRoleAdmin,
    messageConfirmDuplicate,
    onCancel,
    onCancelConfirm,
    onCancelConfirmDuplicate,
    onChangeCountryCode,
    onChangeFileType,
    onChangePagination,
    onChangeTable,
    onOpen,
    onOpenConfirm,
    onOpenConfirmDuplicate,
    perItem,
    push,
    requiredCountryCode,
    requiredFileType,
    rowSelection,
    selectedRowKeys,
    setCurrentUsage,
    setFileCategory,
    setFileInfoList,
    setFileNameList,
    setFileTypeList,
    setFileTypeName,
    setIsDisabledCountries,
    setIsLoading,
    setIsLoadingLayout,
    setIsResetTable,
    setMessageConfirmDuplicate,
    sourceURL,
    stateFileType,
    typeInfo,
    typeInfoAll,
    typeInfoSubAdmin,
  } = useVariables()
  const newData = useRef<UploadFileType[]>([])

  const handleError = (error: AxiosErrorType) => {
    // 「SCR973_エラー情報の表示画面」に遷移する。
    const stateError = pushStateError(error)
    push({ pathname: SCR973, state: stateError })
  }

  const onDisableFiles = () => {
    setIsResetTable(true)
    onCancel()
    setTimeout(() => {
      onChangePagination(1, 25)
      newData.current = fileInfoList.filter(
        (value: UploadFileType) => !selectedRowKeys.includes(value.index),
      )
      setFileInfoList(newData.current)
      setFileNameList(newData.current.map((item) => item.fileName))
      setIsResetTable(false)
    }, 500)
  }

  const onOkConfirm = () => {
    onCancelConfirm()
    const data = fileInfoList.filter((value: UploadFileType) =>
      selectedRowKeys.includes(value.index),
    )

    // アップロードファイル数をチェックする。
    if (data.length > FILE_MAX_UPLOAD) {
      onCancelConfirm()
      openNotification(replaceStringParam(MESSAGE_E0024, FILE_MAX_UPLOAD))
      return
    }

    const _data: UploadFileApiType = {
      fileList: data,
      isOverwrite: false,
    }

    setIsLoading(true)
    // ファイルアップロード処理を行う。
    commonApi
      .addFiles(_data)
      .then((res: FilesUploadResType) => {
        // 画面の表示を更新する。
        const { fail, exists, success } = res
        if (exists.length > 0) {
          onOpenConfirmDuplicate()
          setMessageConfirmDuplicate(
            replaceStringParam(
              messageConfirmDuplicate,
              exists.join(getValueByLanguage("、", ",").toString()),
            ),
          )
        } else if (fail.length > 0) {
          let text = MESSAGE_E0054
          fail.forEach((item) => {
            text += `\n・${item.split(":")[0]}`
          })
          openNotification(<PreLine>{text}</PreLine>)

          const _data = fileInfoList.filter(
            (value: UploadFileType) => !success?.includes(value.fileName),
          )
          setFileInfoList(_data)
          const _fileNameList = _data.map((item) => item.fileName)
          setFileNameList(_fileNameList)
          newData.current = newData.current.filter(
            (value: UploadFileType) => !success?.includes(value.fileName),
          )
        } else {
          const _data = fileInfoList.filter(
            (value: UploadFileType) => !selectedRowKeys?.includes(value.index),
          )
          openNotification(MESSAGE_I0015)
          setIsResetTable(true)
          if (_data.length > 0) {
            setFileInfoList(_data)
            const _fileNameList = _data.map((item) => item.fileName)
            setFileNameList(_fileNameList)
            newData.current = newData.current.filter(
              (value: UploadFileType) =>
                !selectedRowKeys?.includes(value.index),
            )
            onChangePagination(1, 25)
            setIsResetTable(false)
            return
          }
          // 「SCR970_アップロードファイル管理運用画面」に遷移する。
          else push(sourceURL)
        }
      })
      .catch((error: AxiosErrorType) => {
        handleError(error)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const onOkConfirmDuplicate = () => {
    const data = fileInfoList.filter((value: UploadFileType) =>
      selectedRowKeys.includes(value.index),
    )
    const _data: UploadFileApiType = {
      fileList: data,
      isOverwrite: true,
    }
    onCancelConfirmDuplicate()

    setIsLoading(true)
    // ファイルアップロード処理を行う。
    commonApi
      .addFiles(_data)
      .then((res: FilesUploadResType) => {
        // 画面の表示を更新する。
        const { fail, success } = res
        if (fail.length > 0) {
          let text = MESSAGE_E0054
          fail.forEach((item) => {
            text += `\n・${item.split(":")[0]}`
          })
          openNotification(<PreLine>{text}</PreLine>)

          const _data = fileInfoList.filter(
            (value: UploadFileType) => !success?.includes(value.fileName),
          )
          setFileInfoList(_data)
          const _fileNameList = _data.map((item) => item.fileName)
          setFileNameList(_fileNameList)
          newData.current = newData.current.filter(
            (value: UploadFileType) => !success?.includes(value.fileName),
          )
        } else {
          const _data = fileInfoList.filter(
            (value: UploadFileType) => !selectedRowKeys?.includes(value.index),
          )
          openNotification(MESSAGE_I0015)
          setIsResetTable(true)
          if (_data.length > 0) {
            setFileInfoList(_data)
            const _fileNameList = _data.map((item) => item.fileName)
            setFileNameList(_fileNameList)
            newData.current = newData.current.filter(
              (value: UploadFileType) =>
                !selectedRowKeys?.includes(value.index),
            )
            onChangePagination(1, 25)
            setIsResetTable(false)
            return
          }
          // 「SCR970_アップロードファイル管理運用画面」に遷移する。
          else push(sourceURL)
        }
      })
      .catch((error: AxiosErrorType) => {
        handleError(error)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const goToFileManager = () => {
    push(SCR970)
  }

  //  アップロードされるファイルの処理を行う。
  const beforeUpload = (file: RcFile, files: RcFile[]) => {
    const { name, size, type } = file
    const fileSize = size / 1024
    const isAllowedFileType = FILE_TYPE_ALLOW_UPLOAD_EXTENSION.includes(
      String(type),
    )

    const isNameExit = fileInfoList.some(
      (item) =>
        item.fileName === name &&
        item.fileType === fileType &&
        item.countryCode === countryCode,
    )

    if (requiredFileType && !form.getFieldValue("fileType")) {
      form.validateFields(["fileType"])
      return false
    } else {
      form.setFields([{ errors: [], name: "fileType" }])
    }
    if (requiredCountryCode && !form.getFieldValue("countryCode")) {
      form.validateFields(["countryCode"])
      return false
    } else {
      form.setFields([{ errors: [], name: "countryCode" }])
    }

    if (fileSize > FILE_SIZE_LIMIT_KB) {
      openNotification(replaceStringParam(MESSAGE_E0023, FILE_SIZE_LIMIT_MB))
      return false
    }

    if (name.length > FILE_NAME_LIMIT) {
      openNotification(MESSAGE_E0095)
      return false
    }

    if (!isAllowedFileType) {
      openNotification(
        replaceStringParam(MESSAGE_E0022, FILE_TYPE_ALLOW_UPLOAD_ACCEPT),
      )
      return false
    }

    if (isNameExit) {
      openNotification(replaceStringParam(MESSAGE_E0068, file.name))
      return false
    }

    const reader = new FileReader()
    reader.onload = (event: ProgressEvent<FileReader>) => {
      if (event.isTrusted) {
        newData.current.push({
          countryCode,
          countryName: countryName,
          fileCategory,
          fileData: `${event.target?.result}`,
          fileName: file.name,
          fileSize: fileSize,
          fileType: fileType,
          fileTypeName: fileTypeName || "",
          index: file.uid,
        })

        const dataTotal = [...newData.current]

        const totalSize = newData.current.reduce(
          (total, currentFile) => total + Number(currentFile.fileSize),
          0,
        )
        setCurrentUsage(totalSize)
        setFileInfoList(dataTotal)
        const _fileNameList = files.map((item) => item.name)
        setFileNameList([...fileNameList, ..._fileNameList])
      }
    }
    reader.readAsDataURL(file)

    return true
  }

  useEffect(() => {
    // ユーザの画面アクセス許可チェックを処理する。
    if (!isCommonUploadRegister) {
      // 「SCR973_エラー情報の表示画面」に遷移する。
      const { pathname, state } = backToLogin()
      push({ pathname, state })
    } else
      setTimeout(() => {
        setIsLoadingLayout(false)
      }, 500)
  }, [])

  useEffect(() => {
    if (stateFileType) {
      if (typeInfoAll?.length) {
        const _fileCategory = typeInfoAll.find(
          (item) => item.key1 === stateFileType,
        )?.value3
        setFileCategory(_fileCategory || "")
      }
    }
  }, [typeInfoAll])

  useEffect(() => {
    if (typeInfo?.length) {
      if (stateFileType) {
        form.setFieldValue(
          "fileType",
          typeInfo.find((item) => item.value === fileType)?.text,
        )
        const _fileTypeName =
          typeInfo?.find((item) => item.value === stateFileType)?.text || ""
        setFileTypeName(_fileTypeName)
      } else {
        const isMeasuresType = !!typeInfo.filter(
          (item) => item.value === MEASURES_TYPE,
        ).length

        setIsDisabledCountries(isMeasuresType)
        const _typeInfo = isRoleAdmin
          ? typeInfo
          : typeInfoSubAdmin
          ? typeInfo.filter((item) => !ADMIN_TYPE.includes(String(item.value)))
          : typeInfo.filter(
              (item) =>
                item.value !== MEASURES_TYPE &&
                !ADMIN_TYPE.includes(String(item.value)),
            )
        const _fileTypeList = isRoleAdmin
          ? _typeInfo
          : _typeInfo.filter((item) => item.value !== TEMPLATE_TYPE)
        setFileTypeList(_fileTypeList)
      }
    }
  }, [typeInfo])

  return {
    allCountries,
    beforeUpload,
    columns,
    currentUsage,
    dataSource: fileInfoList,
    fileNameList,
    fileTypeList,
    form,
    goToFileManager,
    isCountry,
    isDisabledCountries,
    isHiddenBtn,
    isLoading,
    isLoadingLayout,
    isOpen,
    isOpenConfirm,
    isOpenConfirmDuplicate,
    isResetTable,
    isStateFileType: !!stateFileType,
    messageConfirmDuplicate,
    onCancel,
    onCancelConfirm,
    onCancelConfirmDuplicate,
    onChangeCountryCode,
    onChangeFileType,
    onChangePagination,
    onChangeTable,
    onDisableFiles,
    onOkConfirm,
    onOkConfirmDuplicate,
    onOpen,
    onOpenConfirm,
    perItem,
    requiredCountryCode,
    requiredFileType,
    rowSelection,
  }
}
