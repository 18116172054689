import styled from "styled-components"
import { Title } from "../../../../../../../../../_webui/layout/components/Typography/Title"

const StyledErrorTitle = styled(Title)`
  margin-left: 15px;
`

export const Styled = {
  StyledErrorTitle,
}
