/* eslint-disable complexity */
import { Routers } from "../../../../../../../constant/Routers"
import { useAuthority } from "../../../../../../../utils/hook/useAuthority"
import { replaceString } from "../../../../../../../utils/replaceString"

const { SCR212, SCR214 } = Routers

export const useVariablesAuthority = (pathname: string, id: string) => {
  const {
    isCommonTravelerRefer,
    isRoleAdmin,
    isRoleSubAdmin,
    isRoleUser,
    isScheduleChange,
    isScheduleRegister,
    isScheduleSearch,
    isTravelerSearch,
  } = useAuthority()

  const isSchedulesAdd = pathname.includes("add")
  const isSchedulesEdit = pathname.includes("edit")

  const isNotAccessScreen =
    !isCommonTravelerRefer &&
    ((isSchedulesAdd && !isScheduleRegister) ||
      (isSchedulesEdit && !isScheduleChange))

  const isVisibleOtherRemarks =
    (isRoleAdmin || isRoleSubAdmin) &&
    ((isSchedulesAdd &&
      replaceString(pathname, id) !== replaceString(SCR212, id)) ||
      (isSchedulesEdit &&
        replaceString(pathname, id) !== replaceString(SCR214, id)))

  // 画面上のボタン表示条件
  const isVisibleBtn = isRoleAdmin || isRoleSubAdmin || isRoleUser

  const isVisibleBtnCopy =
    isSchedulesAdd &&
    isVisibleBtn &&
    (isCommonTravelerRefer || isScheduleRegister)

  const isVisibleBtnCancel =
    isVisibleBtn &&
    (isCommonTravelerRefer ||
      (isSchedulesAdd && isTravelerSearch) ||
      (isSchedulesEdit && isScheduleSearch))

  const isVisibleBtnConfirm =
    isVisibleBtn &&
    (isCommonTravelerRefer ||
      (isSchedulesAdd && isScheduleRegister) ||
      (isSchedulesEdit && isScheduleChange))

  return {
    isNotAccessScreen,
    isRoleAdmin,
    isSchedulesAdd,
    isSchedulesEdit,
    isVisibleBtn,
    isVisibleBtnCancel,
    isVisibleBtnConfirm,
    isVisibleBtnCopy,
    isVisibleOtherRemarks,
  }
}
